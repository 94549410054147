import {defer} from '@shopify/remix-oxygen';
import {Suspense} from 'react';
import {Await, useLoaderData, Link} from '@remix-run/react';
import {useOutletContext} from 'react-router-dom';
import {ArrowRightIcon, BooksIcon, BookIcon, StarDrawIcon} from '~/components';
import {MEDIA_FRAGMENT, PRODUCT_CARD_FRAGMENT} from '~/data/fragments';
import {getHeroPlaceholder} from '~/lib/placeholders';
import {AnalyticsPageType, flattenConnection} from '@shopify/hydrogen';
import {
  Slider,
  ProductsList,
  CollectionsList,
  AccountSection,
  AuthorsList,
  ListsList,
} from '~/components';
import FireStoreParser from 'firestore-parser';

export default function Homepage() {
  const {customer} = useOutletContext();
  const {
    primaryHero,
    temeCarti,
    authors,
    recomandari,
    selectiaNoastra,
    categoriiCarti,
    parenting,
    lists,
  } = useLoaderData();

  // TODO: analytics
  // useServerAnalytics({
  //   shopify: {
  //     pageType: ShopifyAnalyticsConstants.pageType.home,
  //   },
  // });

  return (
    <>
      {primaryHero && <Slider items={primaryHero} />}

      <Benefits />

      {recomandari && (
        <Suspense>
          <Await resolve={recomandari}>
            {({collection}) => {
              let {products} = collection;

              if (!products.length > 0) return <></>;
              return (
                <ProductsList
                  label="De citit"
                  title="Recomandări"
                  buttonLabel="Mai multe recomandări"
                  handle="catalin"
                  products={products}
                  customer={customer}
                />
              );
            }}
          </Await>
        </Suspense>
      )}

      {temeCarti && (
        <Suspense>
          <Await resolve={temeCarti}>
            {({collections}) => {
              if (!collections?.nodes) return <></>;
              return (
                <CollectionsList
                  collections={collections.nodes}
                  label="răsfoiește"
                  title="Teme cărți"
                  subtitle="Cărți sau liste special pentru tine."
                />
              );
            }}
          </Await>
        </Suspense>
      )}

      {authors && (
        <Suspense>
          <Await resolve={authors}>
            {({collections}) => {
              if (!collections?.nodes) return <></>;
              return (
                <AuthorsList collections={collections.nodes} title="Autori" />
              );
            }}
          </Await>
        </Suspense>
      )}

      {authors && (
        <Suspense>
          <Await resolve={lists}>
            {({items}) => {
              if (!items) return <></>;

              return (
                <ListsList
                  label={null}
                  buttonLabel="Vezi mai multe"
                  title="Liste"
                  subtitle="Cărți sau liste special pentru tine."
                  className="pt-0 xl:pt-0"
                  lists={items}
                />
              );
            }}
          </Await>
        </Suspense>
      )}

      {categoriiCarti && (
        <Suspense>
          <Await resolve={categoriiCarti}>
            {({collections}) => {
              if (!collections?.nodes) return <></>;
              return (
                <CollectionsList
                  collections={collections.nodes}
                  title="Categorii cărți"
                  subtitle="Cărți sau liste special pentru tine."
                />
              );
            }}
          </Await>
        </Suspense>
      )}

      {selectiaNoastra && (
        <Suspense>
          <Await resolve={selectiaNoastra}>
            {({collection}) => {
              let {products} = collection;
              if (!products.length > 0) return <></>;
              return (
                <ProductsList
                  label="De citit"
                  buttonLabel="Vezi mai multe"
                  title="Selecția noastră"
                  subtitle="Cărți sau liste special pentru tine."
                  handle="selectia-noastra"
                  className="pt-0 xl:pt-0"
                  products={products}
                  customer={customer}
                />
              );
            }}
          </Await>
        </Suspense>
      )}

      <AccountSection customer={customer} />

      {parenting && (
        <Suspense>
          <Await resolve={parenting}>
            {({collection}) => {
              let {products} = collection;
              if (!products.length > 0) return <></>;
              return (
                <ProductsList
                  label="De citit"
                  buttonLabel="Vezi mai multe"
                  title="Parenting"
                  subtitle="Cărți sau liste special pentru tine."
                  handle="parenting"
                  className="pt-0 xl:pt-0"
                  products={products}
                  customer={customer}
                />
              );
            }}
          </Await>
        </Suspense>
      )}
    </>
  );
}

export function Benefits() {
  return (
    <>
      <div className="lg:hidden px-4 pt-10">
        <div className="flex flex-col border border-solid border-line rounded-3xl font-serif uppercase">
          <a
            href="/categorii/recomandari"
            className="p-5 border-b border-solid border-line flex justify-between items-center"
          >
            Toate cărțile
            <ArrowRightIcon />
          </a>

          {/* TODO: Pagina de categorii, colectii */}
          <a
            href="/categorii"
            className="p-5 border-b border-solid border-line flex justify-between items-center"
          >
            Categorii și teme
            <ArrowRightIcon />
          </a>
          <a href="/profil" className="p-5 flex justify-between items-center">
            Cont nou
            <ArrowRightIcon />
          </a>
        </div>
      </div>
      <div className="hidden lg:block pt-10 pb-10 xl:pt-20 xl:pb-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-12 gap-6 items-start">
            <div className="col-span-12 lg:col-span-4">
              <div className="flex">
                <div className="icon icon-shield pt-3">
                  <BooksIcon />
                </div>
                <div className="lg:text-base xl:text-lg text-left pl-5 font-serif">
                  <span className="block uppercase mb-2">
                    Nu te poti decide?
                  </span>
                  <span className="block mb-6">Vezi sugestiile noastre</span>
                  <a
                    href="/categorii/recomandari"
                    className="text-primary border-b border-solid border-primary pb-1"
                  >
                    Vezi cărți
                  </a>
                </div>
              </div>
            </div>

            <div className="col-span-12  lg:col-span-4">
              <div className="flex">
                <div className="icon icon-shipping pt-3">
                  <BookIcon />
                </div>
                <div className="lg:text-base xl:text-lg text-left pl-5 font-serif">
                  <span className="block uppercase mb-2">
                    CAUTĂ și descoperă
                  </span>
                  <span className="block mb-6">
                    Orientează-te prin intermediul categoriilor
                  </span>
                  <a
                    href="/categorii"
                    className="text-primary border-b border-solid border-primary pb-1"
                  >
                    Vezi categoriile
                  </a>
                </div>
              </div>
            </div>

            <div className="col-span-12  lg:col-span-4">
              <div className="flex">
                <div className="icon icon-gift pt-3">
                  <StarDrawIcon />
                </div>
                <div className="lg:text-base xl:text-lg text-left pl-5 font-serif">
                  <span className="block uppercase mb-2">Participă și tu</span>
                  <span className="block mb-6">
                    Intră și tu in comunitatea noastră
                  </span>
                  <a
                    href="/profil"
                    className="text-primary border-b border-solid border-primary pb-1"
                  >
                    Cont nou
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const COLLECTION_CONTENT_FRAGMENT = `#graphql
  fragment CollectionContent on Collection {
    description
    handle
    id
    title
    image {
      id
      url
      altText
      width
      height
    }
    products(first: 1) {
      nodes {
        handle
        id
        title
        featuredImage {
          url(transform: {maxWidth: 432})
          height
          width
        }
        collections(first: 250) {
          nodes {
            handle
            id
            title
          }
        }
        variants(first: 1) {
          nodes {
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

const COLLECTION_PRODUCTS_FRAGMENT = `#graphql
  fragment CollectionProducts on Collection {
    description
    handle
    id
    title
    image {
      id
      url
      altText
      width
      height
    }
    products(first: 10) {
      nodes {
        handle
        id
        title
        featuredImage {
          url(transform: {maxWidth: 432})
          height
          width
        }
        collections(first: 250) {
          nodes {
            handle
            id
            title
          }
        }
        variants(first: 1) {
          nodes {
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

const HOMEPAGE_SEO_QUERY = `#graphql
  ${COLLECTION_CONTENT_FRAGMENT}
  query collectionContent($country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    hero: collections(
      first: 3
      query: "id:381352935671"
      sortKey: UPDATED_AT
    ) {
      nodes {
        ...CollectionContent
      }
    }
    shop {
      name
      description
    }
  }
`;

export const HOMEPAGE_PRODUCTS_QUERY = `#graphql
  ${COLLECTION_PRODUCTS_FRAGMENT}
  query collectionProducts($handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    collection: collection(handle: $handle) {
      ...CollectionProducts
    }
  }
`;

// @see: https://shopify.dev/api/storefront/latest/queries/collections
export const FEATURED_COLLECTIONS_QUERY = `#graphql
  query homepageFeaturedCollections($country: CountryCode, $language: LanguageCode, $query: String!)
  @inContext(country: $country, language: $language) {
    collections(
      first: 10,
      sortKey: UPDATED_AT
      query: $query
    ) {
      nodes {
        id
        title
        handle
        count: metafield(namespace: "cpcm", key: "total_carti") {
          value
        }
        image {
          altText
          width
          height
          url(transform: {maxWidth: 268})
          id
        }
      }
    }
  }
`;

async function getProducts({storefront, session, handle}) {
  const {language, country} = storefront.i18n;
  const data = await storefront.query(HOMEPAGE_PRODUCTS_QUERY, {
    variables: {
      /**
       * Country and language properties are automatically injected
       * into all queries. Passing them is unnecessary unless you
       * want to override them from the following default:
       */
      country,
      language,
      handle,
    },
  });

  {
    /* const customerAccessToken = await session.get('customerAccessToken');
  const customer = customerAccessToken
    ? await getCustomer(storefront, customerAccessToken)
    : null;
  let review = null; */
  }

  //console.log('Collec', collection);

  {
    /* let promises = [];
  let nodes = data?.collection?.products?.nodes || [];
  for (let i = 0; i < nodes.length; i++) {
    let product = nodes[i];
    const docRef = doc(db, 'products', product.handle);
    promises.push(getDoc(docRef));
  }

  let docs = await Promise.all(promises); */
  }

  //let products = [];
  {
    /* for (let i = 0; i < nodes.length; i++) {
    let product = nodes[i];
    let doc = find(docs, function (o) {
      return o.id === product.handle;
    });
    let data = {};
    if (doc) {
      data = {...doc.data()};

      if (customer && data?.count_reviews && data?.count_reviews > 0) {
        const collRef = collection(db, 'reviews');
        const q = query(
          collRef,
          where('product_handle', '==', product.handle),
          where('status', '==', 'published'),
          where(
            'customer',
            '==',
            customer.id.replace('gid://shopify/Customer/', ''),
          ),
          orderBy('created_at', 'desc'),
          limit(1),
        );

        const snapshot = await getDocs(q);
        snapshot.docs.map((doc) => {
          review = {...doc.data(), id: doc.id};
        });
      }
    }

    products.push({
      ...product,
      ...data,
      collections: flattenConnection(product.collections),
      review,
    });
  } */
  }

  let products = flattenConnection(data.collection.products);

  return {collection: {...data.collection, products}};
}

const CUSTOMER_QUERY = `#graphql
  query CustomerDetails(
    $customerAccessToken: String!
    $country: CountryCode
    $language: LanguageCode
  ) @inContext(country: $country, language: $language) {
    customer(customerAccessToken: $customerAccessToken) {
      firstName
      lastName
      phone
      email
      id
    }
  }
`;

export async function getCustomer(storefront, customerAccessToken) {
  const data = await storefront.query(CUSTOMER_QUERY, {
    variables: {
      customerAccessToken,
      country: storefront.i18n.country,
      language: storefront.i18n.language,
    },
  });

  return data.customer;
}

async function getLists({endCursor, sortBy, direction, number, status}) {
  let lists = [];

  const pageSize = parseInt(number) + 1;
  let pageToken = endCursor || '';

  const response = await fetch(
    'https://firestore.googleapis.com/v1/projects/carti-pentru-copilul-meu/databases/(default)/documents:runQuery',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: pageToken
        ? JSON.stringify({
            structuredQuery: {
              from: [{collectionId: 'lists'}],
              orderBy: [{field: {fieldPath: sortBy}, direction}],
              where: {
                compositeFilter: {
                  filters: [
                    {
                      fieldFilter: {
                        field: {fieldPath: 'status'},
                        op: 'EQUAL',
                        value: {stringValue: status},
                      },
                    },
                  ],
                  op: 'AND',
                },
              },
              startAt: {
                values: [
                  {
                    timestampValue: pageToken,
                  },
                ],
                before: true,
              },
              limit: pageSize,
            },
          })
        : JSON.stringify({
            structuredQuery: {
              from: [{collectionId: 'lists'}],
              orderBy: [{field: {fieldPath: sortBy}, direction}],
              where: {
                compositeFilter: {
                  filters: [
                    {
                      fieldFilter: {
                        field: {fieldPath: 'status'},
                        op: 'EQUAL',
                        value: {stringValue: status},
                      },
                    },
                  ],
                  op: 'AND',
                },
              },
              limit: pageSize,
            },
          }),
    },
  );

  const data = await response.json();

  const documents = await FireStoreParser(data);

  documents &&
    documents.length > 0 &&
    documents.map((doc, index) => {
      const d = doc.document;
      if (d) {
        const id = d.name.split('/').pop();
        if (
          index === documents.length - 1 &&
          documents.length !== 1 &&
          documents.length > number
        ) {
          pageToken = d.fields[sortBy];
        } else {
          lists.push({...d.fields, id});
        }
      }
    });

  const hasNextPage = documents.length > number;
  pageToken = hasNextPage ? pageToken : null;

  return {
    items: [...lists],
    pageInfo: {
      hasNextPage,
      endCursor: pageToken,
    },
  };
}
